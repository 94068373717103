import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/josh/Code/hub/joshayoung_website/src/components/layout-markdown.js";
import Chrome from "../snippets/chrome.js";
import Docker from "../snippets/docker.js";
import HTTP from "../snippets/http.js";
import Postgresql from "../snippets/postgresql.js";
import Kanban from "../snippets/kanban.js";
import Rubymine from "../snippets/rubymine.js";
import TMUX from "../snippets/tmux.js";
import VIM from "../snippets/vim.js";
import VSCode from "../snippets/vs-code.js";
import AnchorList from "../components/anchor-list";
import SnippetAnchorList from "../snippets/snippet-anchor-list";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const VisualStudio = makeShortcode("VisualStudio");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Code Snippets`}</h1>
    <p>{`Below, I have listed some common code snippets that I commonly go back to reference. Maybe you will find some of these useful. I update these routinely and add new ones as I run across them.`}</p>
    <AnchorList mdxType="AnchorList">
  <SnippetAnchorList mdxType="SnippetAnchorList" />
    </AnchorList>
    <h3><a id="ansible"></a>{`Ansible`}</h3>
    <h4>{`Ping Command:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ansible all --key-file ~/.ssh/my_private_key -i inventory -m ping
`}</code></pre>
    <h4>{`Run gather facts command:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ansible all -m gather_facts --limit 192.168.1.100
`}</code></pre>
    <h4>{`Become sudo first:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ansible all -m apt -a update_cache=true --become --ask-become-pass
`}</code></pre>
    <h4>{`Run a playbook:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ansible-playbook --ask-become-pass playboo.yml
`}</code></pre>
    <h3><a id="asdf"></a>{`ASDF`}</h3>
    <ul>
      <li parentName="ul">{`List versions of plugin installed`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  asdf list nodejs
`}</code></pre>
    <ul>
      <li parentName="ul">{`Show Current Plugins with the Global Version`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  asdf current
`}</code></pre>
    <ul>
      <li parentName="ul">{`Reshim Note:`}
        <ul parentName="li">
          <li parentName="ul">{`After running a `}<inlineCode parentName="li">{`asdf reshim [plugin]`}</inlineCode>{` (i.e. `}<inlineCode parentName="li">{`asdf reshim nodejs`}</inlineCode>{`),
you might need to reload or shutdown and reopen your terminal for it to properly take affect.`}</li>
        </ul>
      </li>
    </ul>
    <h3><a id="bash"></a>{`Bash`}</h3>
    <h4>{`Services Running on Port`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  sudo lsof -i tcp:3000
`}</code></pre>
    <h4>{`If/Else`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  if [ expression ]
    ...
  then
    ...
  else
    ...
  fi
`}</code></pre>
    <h4>{`Assign Variable (spacing is important)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  the_var="a string"
  echo $the_var
`}</code></pre>
    <h4>{`Show a process running on a particular port`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  netstat -na | grep -i LISTEN | grep '2000\\|3000'
`}</code></pre>
    <h3><a id="text"></a>{`Text`}</h3>
    {/* Adopted from: https://www.lifewire.com/typing-quotes-apostrophes-and-primes-1074104 */}
    <h4>{`Keyboard Shortcuts for Quote Marks`}</h4>
    <table>
  <thead>
    <tr>
      <th>Key</th>
      <th>Description</th>
      <th>Windows</th>
      <th>Mac</th>
      <th>HTML</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>…</th>
      <td>Ellipsis</td>
      <td>alt+0133</td>
      <td>option+;</td>
      <td>&amp;hellip;</td>
    </tr>
    <tr>
      <th>‘</th>
      <td>Opening Single Apostrophe</td>
      <td>alt+0145</td>
      <td>option+]</td>
      <td>&amp;lsquo;</td>
    </tr>
    <tr>
      <th>’</th>
      <td>Closing Single Apostrophe</td>
      <td>alt+0146</td>
      <td>option+shift+]</td>
      <td>&amp;rsquo;</td>
    </tr>
    <tr>
      <th>“</th>
      <td>Opening Double Quote</td>
      <td>alt+0147</td>
      <td>option+[</td>
      <td>&amp;ldquo;</td>
    </tr>
    <tr>
      <th>”</th>
      <td>Closing Double Quote</td>
      <td>alt+0148</td>
      <td>option+shift+[</td>
      <td>&amp;rdquo;</td>
    </tr>
    <tr>
      <th>–</th>
      <td>En Dash</td>
      <td>alt+0150</td>
      <td>Option + dash</td>
      <td>&amp;ndash;</td>
    </tr>
    <tr>
      <th>—</th>
      <td>Em Dash</td>
      <td>alt+0151</td>
      <td>Option + Shift + dash</td>
      <td>&amp;mdash;</td>
    </tr>
  </tbody>
    </table>
    <h3><a id="curl"></a>{`Curl`}</h3>
    <h4>{`Get HTTP Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  curl -I example.com
`}</code></pre>
    <h4>{`Get Just Response Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  curl -s -o /dev/null -w "%{http_code}" example.com
`}</code></pre>
    <h4>{`Curl POST`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl --data "information=to&send=to the server" http://example.com
`}</code></pre>
    <h3><a id="blazor"></a>{`Blazor`}</h3>
    <h3><a id="bundler"></a>{`Bundler`}</h3>
    <h4>{`Install a different version of bundler`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  gem install bundler -v 1.0.10
`}</code></pre>
    <h4>{`Run `}<inlineCode parentName="h4">{`bundle`}</inlineCode>{` with a different version:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  bundle _2.0.15_ install
`}</code></pre>
    <h3><a id="csharp">{`C#`}</a></h3>
    <h4>{`Install .NET Package from Command Line`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`dotnet add package PackageName -v 0.1-pre -s https://url/path/nuget/v3/index.json
`}</code></pre>
    <h4>{`Empty IEnumerable`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`  Enumerable.Empty<XElement>();
`}</code></pre>
    <h4>{`Static Instance`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`  public class MyStaticInstance
  {
    // Our static instantiation:
    public static MyStaticInstance Instance { get; } = new MyStaticInstance();

    // Prevents new instantiations from being possible:
    private MyStaticInstance() { }
  }
`}</code></pre>
    <h4>{`Test for Property Changed with xUnit`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`  Assert.PropertyChanged(viewModel, nameof(viewModel.MyProperty), () => {
    anotherProperty.Title = "new title";
  });
`}</code></pre>
    <h4>{`Invoke Property Change Event After List's Add()`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`  MyListOfValues.Add("an item");
  PropertyChanged?.Invoke(this, new PropertyChangedEventArgs(nameof(MyListOfValues)));
`}</code></pre>
    <h4>{`Unused variable`}</h4>
    <ul>
      <li parentName="ul">{`For an unused variable (in a loop for instance), you can use an underscore `}<inlineCode parentName="li">{`_`}</inlineCode>{`.`}</li>
    </ul>
    <h4>{`Property vs Field`}</h4>
    <ul>
      <li parentName="ul">{`A property is a field that has an implicit getter and setter set for it. This getter/setter is setup using the `}<inlineCode parentName="li">{`get;`}</inlineCode>{` and `}<inlineCode parentName="li">{`set;`}</inlineCode>{`. Usually this manifests itself as a public getter that returns a private field. Here is an example:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`public string Name { get; set; };`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`A field in C# does not have a backing field and has no getter or setter set for it. Here is an example:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`public string Name;`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h4>{`Prefer Named Arguments:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`  public void FullName(string first, string last)
  {

  }

  // Call method like this:
  fullName(first: "Joe", last: "Smith");

  // Not like this:
  fullName("Joe", "Smith");
`}</code></pre>
    <h3><a id="css"></a>{`CSS`}</h3>
    <h4>{`Switch to box sizing`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
`}</code></pre>
    <h4>{`Clear Floats`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
`}</code></pre>
    <h4>{`Margin/Padding Shorthand`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`//top right bottom left:
margin(or padding): 10px 20px 10px 20px;

//top/bottom left/right:
margin(or padding): 10px 20px;

//top left/right bottom:
margin(or padding): 10px 20px 10px;
`}</code></pre>
    <h3><a id="dev_tools"></a>{`Dev Tools`}</h3>
    <h4>{`Chrome`}</h4>
  <Chrome mdxType="Chrome" />
    <h3><a id="docker"></a>{`Docker`}</h3>
  <Docker mdxType="Docker" />
    <h3><a id="html5"></a>{`HTML5`}</h3>
    <h4>{`Unicode/Emojis`}</h4>
    <p>{`You can get the unicode emoji's from this page: `}<a parentName="p" {...{
        "href": "http://unicode.org/emoji/charts/full-emoji-list.html"
      }}>{`emoji list`}</a>{`.
All you have to do is remove the 'U+' from the first part of the code and replace it with '&#x'. End the unicode string with a semicolon.
For example, to use the smiley emoji, change it from: U+1F600 to '`}{`&`}{`#x1F600;'.
To use a unicode character with the css 'before' or 'after' sudo-selector, remove the 'U+' from and replace it with '\\'. So for the css ':before' or ':after', it would be '\\1F600'. Of course, to use unicode on your site, you have to have the utf8 tag in your site header:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<meta charset="utf-8" />
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://www.w3.org/International/tutorials/tutorial-char-enc"
      }}>{`Reference`}</a></p>
    <h5>{`Code Pen`}</h5>
    <p data-height="265" data-theme-id="0" data-slug-hash="vrbzGq" data-default-tab="html,result" data-user="joshayoung" data-embed-version="2" data-pen-title="UTF8" className="codepen">See the Pen <a href="https://codepen.io/joshayoung/pen/vrbzGq/">UTF8</a> by Josh Young (<a href="https://codepen.io/joshayoung">@joshayoung</a>) on <a href="https://codepen.io">CodePen</a>.</p>
    <h4>{`HTML5 Doctype`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
`}</code></pre>
    <h4>{`Tags`}</h4>
    <ul>
      <li parentName="ul">{`<`}{`cite`}{`>`}{` - cite a source`}</li>
      <li parentName="ul">{`<`}{`kdb`}{`>`}{` - text entered from a keyboard (i.e. `}<inlineCode parentName="li">{`cd`}</inlineCode>{`, `}<inlineCode parentName="li">{`pwd`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`<`}{`pre`}{`>`}{` - preserves white space in html output`}</li>
      <li parentName="ul">{`<`}{`var`}{`>`}{` - used for outputting a variable in html`}</li>
      <li parentName="ul">{`<`}{`del`}{`>`}{` - show removed text (sometimes styled with strike-through mark)`}</li>
    </ul>
    <h3><a id="http"></a>{`HTTP`}</h3>
  <HTTP mdxType="HTTP" />
    <h3><a id="ufw"></a>{`UFW`}</h3>
    <p>{`UFW by default will allow all outgoing connections but deny any incoming connections.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Show firewall status/rules:
  ufw status

  # Enable firewall
  ufw enable
`}</code></pre>
    <h4>{`Application Profiles`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Show all application profiles:
  ufw app list

  # Show which ports/protocols a rule will enable:
  ufw app info 'OpenSSH'
`}</code></pre>
    <h4>{`Set default egress/ingress rules`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Deny egress and ingress:
  ufw default deny outgoing
  ufw default deny incoming
`}</code></pre>
    <h4>{`Open Up Ports`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Allow HTTP Service:

  ufw allow http

  # or:

  ufw allow 80/tcp
  # or allow application profile (nginx, apache, etc):
  ufw allow 'Nginx HTTP'

  # Allow a specific port:
  ufw allow 8080/tcp

  # Allow a specific IP:
  sudo ufw allow from 192.168.3.100

  # Limit to one port:
  sudo ufw allow from 192.168.3.100 to any port 80

  # Allow the whole subnet:
  ufw allow from 192.168.3.0/24 to any port 80
`}</code></pre>
    <h4>{`Close Ports/Delete Rules`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Close port 80
  ufw deny 80/tcp

  # Deny from specific IP:
  ufw deny from 192.168.3.100

  # Deny IP and Port:
  ufw deny from 192.168.3.100 to any port 80

  # Delete HTTP rule:
  ufw delete allow www
  # or:
  ufw delete allow 80/tcp
`}</code></pre>
    <h3><a id="firewalld"></a>{`Firewalld`}</h3>
    <p>{`Firewalld is manipulating iptables behind the scene. Firewalld does have extended features in comparison to just using iptables. In the future iptables will be replaced with nftables. Firewalld will also serve as a font-end for nftables.`}</p>
    <h4>{`Default Zones`}</h4>
    <ul>
      <li parentName="ul">{`block - ingress rejected, egress allowed`}</li>
      <li parentName="ul">{`dmz - for DMZ servers`}</li>
      <li parentName="ul">{`drop - ingress dropped, egress allowed`}</li>
      <li parentName="ul">{`external - use when you have NAT masquerading`}</li>
      <li parentName="ul">{`home - other machines trusted`}</li>
      <li parentName="ul">{`internal - use when server is a router/gateway machine`}</li>
      <li parentName="ul">{`public - other machines untrusted`}</li>
      <li parentName="ul">{`trusted - connections accepted and machines trusted`}</li>
      <li parentName="ul">{`work - other machines trusted`}</li>
    </ul>
    <h4>{`Firewalld Main Commands`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Show all zones:
  firewall-cmd --get-zones

  # Show active zones:
  firewall-cmd --get-active-zones

  # Show default zone:
  firewall-cmd --get-default-zone

  # Show all services:
  firewall-cmd --get-services
`}</code></pre>
    <h4>{`Change Zones/Interfaces`}</h4>
    <p>{`Each network inteface can be assigned to a different zone.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Change 'eth0' to 'work' zone:
  firewall-cmd --zone=work --change-interface=eth0
`}</code></pre>
    <h4>{`Show Current Settings`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # List current settings on 'public' zone:
  sudo firewall-cmd --zone=public --list-all

  # Show open ports:
  sudo firewall-cmd --zone=public --list-ports
`}</code></pre>
    <h4>{`Open Ports/Services`}</h4>
    <p>{`Without `}<inlineCode parentName="p">{`--permanent`}</inlineCode>{`, the firewall rule will take affect but will not persist after a reboot.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Open port 80:
  firewall-cmd --permanent --zone=public --add-port=80/tcp

  # Add FTP Service:
  firewall-cmd --permanent --zone=public --add-service=ftp

  # Reload the firewall for these to take affect:
  firewall-cmd --reload
`}</code></pre>
    <h4>{`Remove Service/Port`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Remove the open port:
  firewall-cmd --zone=public --remove-port=80/tcp

  # Remove the service:
  firewall-cmd --zone=public --remove-service=ftp

  # Add the '--permanent' flag to make the removal permanent:
  firewall-cmd --zone=public --remove-port=80/tcp --permanent
  firewall-cmd --zone=public --remove-service=ftp --permanent
`}</code></pre>
    <h3><a id="gatsby"></a>{`Gatsby`}</h3>
    <h4>{`Starting Local Server`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  gatsby develop
  # or
  npm run develop
`}</code></pre>
    <h3><a id="java"></a>{`Java`}</h3>
    <h4>{`Build a Jar:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`  mvn package
  java -jar target/my_jar_name.jar
`}</code></pre>
    <h4>{`Interface Naming`}</h4>
    <ul>
      <li parentName="ul">{`When you have more than one interface use a name that corresponds to the domain.`}</li>
      <li parentName="ul">{`When you have only one interface, name the interface "name" + "Impl".`}</li>
    </ul>
    <h3><a id="git"></a>{`Git`}</h3>
    <h4>{`Merge with a New Hash`}</h4>
    <ul>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`--squash`}</inlineCode>{` flag with the `}<inlineCode parentName="li">{`merge`}</inlineCode>{` command if you want to include code from one branch into another branch, but you do not want the commit to have the same hash value for the commit.`}</li>
      <li parentName="ul">{`If you were to use a `}<inlineCode parentName="li">{`cherry-pick`}</inlineCode>{` or a 'regular' `}<inlineCode parentName="li">{`merge`}</inlineCode>{`, the the included commit hash would be the same as the original commit hash.`}</li>
    </ul>
    <h4>{`Set a Different Author for a Repository`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`git config --local user.email email@example.com`}</inlineCode></li>
    </ul>
    <h4>{`Reset the Previous' Commit's Email Address`}</h4>
    <ul>
      <li parentName="ul">
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`git reset HEAD~1
git commit --amend --author="First Last <email@example.com>" --no-edit
git push --force-with-lease
`}</code></pre>
      </li>
    </ul>
    <h4>{`Contributing to an open source project`}</h4>
    <ol>
      <li parentName="ol">{`Fork the project on github.`}</li>
      <li parentName="ol">{`Create a feature branch: `}<inlineCode parentName="li">{`git checkout -b "feature-branch-name"`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Commit changes to your feature branch: `}<inlineCode parentName="li">{`git commit -am "commit message"`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Push the feature branch up: `}<inlineCode parentName="li">{`git push origin feature-branch-name`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Creating a PR from this branch will open up a PR in the forked repo.`}</li>
    </ol>
    <h4>{`Copy Contents from One Branch to a New Branch:`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`git checkout -b my_new_branch_name my_old_branch_name`}</inlineCode></li>
    </ul>
    <h4>{`Pull Down / Commit to a Remote PR:`}</h4>
    <ol>
      <li parentName="ol">{`Copy the remote branch name in the PR list (i.e. 'thebrnm:master')`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Remove the `}<inlineCode parentName="li">{`:master`}</inlineCode>{` off the end.`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><inlineCode parentName="li">{`git remote add thebrnm git@github.com:thebrnm/the_repo.git`}</inlineCode></li>
    </ol>
    <ul>
      <li parentName="ul">{`In this case 'origin' started as: `}<inlineCode parentName="li">{`git@github.com:thebrnm/the_repo.git`}</inlineCode></li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><inlineCode parentName="li">{`git fetch thebrnm`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`git checkout -b thebrnm-master thebrnm/master`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`git commit -am "My Commit Message"`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`git push thebrnm HEAD:master`}</inlineCode></li>
    </ol>
    <h4>{`Remove File from Repo, and File System`}</h4>
    <p>{`This will remove the file from your repo, and the filesystem.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git rm file_name
`}</code></pre>
    <h4>{`Push up tags:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{` git push --follow-tags
`}</code></pre>
    <h4>{`Remove File from Repo, not File System`}</h4>
    <p>{`This will remove the file from your repo, but not from the file system.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git rm --cached file_name
`}</code></pre>
    <h4>{`Remove Folder from Repo, and File System`}</h4>
    <p>{`This will remove the directory from your repo, and the filesystem.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git rm -rvf file_name
`}</code></pre>
    <h4>{`Remove Folder from Repo, not File System`}</h4>
    <p>{`This will remove the directory from your repo, but not from the file system.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git rm -r --cached my_folder_name
`}</code></pre>
    <h4>{`Show Contents of Commit`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git show [commit hash]
`}</code></pre>
    <h4>{`Change Base Branch`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git rebase --onto new_branch old_branch branch_you_are_moving
`}</code></pre>
    <h4>{`Turn off the pager`}</h4>
    <p>{`Add `}<inlineCode parentName="p">{`--no-pager`}</inlineCode>{` to a command`}</p>
    <h4>{`Restore a Deleted File`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git checkout [removed commit]^ -— [path to flle]
`}</code></pre>
    <h4>{`Restore a Deleted File Before Commit`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git reset
  git checkout -- /path/to/deleted/file.txt
`}</code></pre>
    <h4>{`Delete Branches`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Delete a local branch:
  git branch -d my_local_branch

  # Delete a remote branch:
  git push origin --delete my_remote_branch
`}</code></pre>
    <h4>{`Show which Remote Branch`}</h4>
    <p>{`This shows which remote branch you are tracking and how far behind your local version is from the remote.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git branch -vv
`}</code></pre>
    <h4>{`Push changes, then revert locally`}</h4>
    <p>{`This will allow you to push up a feature branch and then revert your local changes to see the diff.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git push origin my_feature_branch

  # Revert changes so you can see the diff (locally):
  git reset HEAD~
`}</code></pre>
    <h4>{`HEAD`}</h4>
    <p>{`HEAD is the most recent commit on your present branch.`}</p>
    <h4>{`Checkout Previous Commit`}</h4>
    <p>{`This will put you in a detached HEAD state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git checkout [hash of previous commit]
`}</code></pre>
    <h4>{`Checkout Remote Branch`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git fetch origin
  git checkout -b my_branch_name orgin/my_branch_name

  # or:

  git fetch
  git checkout my_branch_name

  # if we have local branch with same name do
  # this to prevent conflicts with the local branch:

  git fetch origin
  git checkout --track origin/my_branch_name
`}</code></pre>
    <h4>{`Git Reset`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git reset --soft HEAD^3

  "'mixed' is the default if you run \`git reset\`:
  git reset --mixed HEAD^3

  git reset --hard HEAD^3
`}</code></pre>
    <ul>
      <li parentName="ul">{`--soft (keeps staged changes)`}</li>
      <li parentName="ul">{`--mixed (changes present, not staged)`}</li>
      <li parentName="ul">{`--hard (does not preserve uncommitted changes)`}</li>
    </ul>
    <h4>{`Edit Commit Message`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git commit --amend
`}</code></pre>
    <h4>{`Revert a file to state at previous commit`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git checkout -- my_file.txt
`}</code></pre>
    <h4>{`Commit Messages`}</h4>
    <p>{`Try to use present tense for your commit message (i.e. "`}<strong parentName="p">{`Add`}</strong>{` new class for students" not: "Added new class for students")`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git commit -m "Correct site map"
`}</code></pre>
    <h4>{`Pick Files to Stash`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash -p
`}</code></pre>
    <h4>{`Show File in Stash`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash show stash@{0}
`}</code></pre>
    <h4>{`Checkout a file that is stashed`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git checkout stash@{0} -- path/to/file
`}</code></pre>
    <h4>{`Revert Uncommitted Changes`}</h4>
    <p>{`Leaving out the \`stash@{2} variable below will run the stash command on top stash on the stack.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash apply stash@{1}
  git stash drop stash@{2}
`}</code></pre>
    <h4>{`Retrieve a stashed file`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git checkout [name of stash] -- [filename]
`}</code></pre>
    <h4>{`Stash all files (including files in .gitignore)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash --all
`}</code></pre>
    <h4>{`Stash tracked and untracked files`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash save --include-untracked
`}</code></pre>
    <h4>{`Revert One File`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git checkout -- path/to/file.txt
`}</code></pre>
    <h4>{`Show Files in Commit`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git diff-tree --no-commit-id --name-only -r [commit hash]
`}</code></pre>
    <h4>{`Show Diff in Staged Files`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git diff --staged
`}</code></pre>
    <h4>{`Rename a Local Branch`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git branch -m &lt;oldname&gt; &lt;newname&gt;
`}</code></pre>
    <h4>{`Move Remote PR Branch Locally`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git fetch origin pull/ID/head:BRANCHNAME
`}</code></pre>
    <h4>{`Saves Current Changes with Stash`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash
  or:
  git stash save "Name for the stash"
`}</code></pre>
    <h4>{`Files in stash`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash list --stat
`}</code></pre>
    <h4>{`Show files in stash`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash show stash@{2}
`}</code></pre>
    <h4>{`Remove all stashes`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash clear
`}</code></pre>
    <h4>{`Restores Most Recently Stashed Changes`}</h4>
    <p>{`This command will run `}<inlineCode parentName="p">{`git stash apply`}</inlineCode>{` and then `}<inlineCode parentName="p">{`git stash drop`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash pop
`}</code></pre>
    <h4>{`List Stashes`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash list
`}</code></pre>
    <h4>{`Restore Specific Stash`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git stash pop stash@{3}
`}</code></pre>
    <h4>{`Create and switch to branch`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git checkout -b new_branch_name
`}</code></pre>
    <h4>{`Show Remote Branches`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git branch -r
`}</code></pre>
    <h4>{`Delete local branch`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git branch -d local_branch_name
`}</code></pre>
    <h4>{`Git Tags`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # show all tags
  git tag
  # checks out the code with this tag
  git checkout [name of tag]
  # adds a tag
  git tag -a [tag name] -m tag description]
  # pushes tags
  git push --tags
`}</code></pre>
    <h4>{`Show Diffs`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git diff HEAD^ (parent)
  git diff HEAD^^ (grandparent)
  git diff HEAD~6 (six commits ago)
`}</code></pre>
    <h4>{`Compare Commits`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git diff HEAD^..HEAD
  git diff 58786f..98f7f0
  git diff master another_branch
`}</code></pre>
    <h4>{`Stop tracking in Repo`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git rm --cached errors.txt
`}</code></pre>
    <h4>{`Remove one file from `}<inlineCode parentName="h4">{`git add`}</inlineCode></h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git reset HEAD &lt;file&gt;
`}</code></pre>
    <h4>{`Remove files added with `}<inlineCode parentName="h4">{`git add .`}</inlineCode></h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git reset
`}</code></pre>
    <h4>{`Restore a deleted file`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # First find the hash:
  git reflog
  git log --walk-reflogs (more detail)

  # Then:
  git reset --hard 7980f
  # or:
  git reset --hard HEAD@{1}

  # Create a new branch with reflog hash:
  git branch [new branch name] 890fs4
  or:
  git branch [new branch name] HEAD@{1}
`}</code></pre>
    <h4>{`Clone a local repo as a backup`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git clone local_repo local_repo_backup
`}</code></pre>
    <h4>{`Rewrite History`}</h4>
    <p>{`Once a file is added to git (after the commit) it is permanently in the repo. The only way to remove it is to rewrite history. However, do this with extreme care! Make a backup of your code first with `}<inlineCode parentName="p">{`clone`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git filter-branch --tree-filter 'rm -f old_file.sh -- --all'

  # or you can just remove the file from the repo:
  git filter-branch --index-filter 'git rm --cached --ignore-unmatch old_file.sh'

  # Sometimes when you re-write you will get empty commits, to delete these do:
  git filter-branch -f --prune-empty -- --all
`}</code></pre>
    <h4>{`Commit Order Differences`}</h4>
    <p>{`Order from top to bottom:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git log (newest to oldest)
  git rebase -i HEAD~3 (oldest to newest)
`}</code></pre>
    <h4>{`Roll back previous commit (preserving file changes)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git reset --soft HEAD~1
  or
  git reset --soft HEAD^
`}</code></pre>
    <h4>{`Cherry Pick`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git cherry-pick 97589f

  # Edit Commit:
  git cherry-pick --edit 987df0

  # Combine two commits:
  git cherry-pick --no-commit 9876f 78979f
  git commit -m "Combine two commits"
`}</code></pre>
    <h4>{`Add file to last commit`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git add newfile.sh
  git commit --amend -m "Add file to repo"
  or:
  git commit --amend --no-edit
`}</code></pre>
    <h4>{`Roll back previous commit (discarding file changes)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git reset --hard HEAD~1
  # or:
  git reset --hard HEAD^
  # or the two previous  commits:
  git reset --hard HEAD^^
`}</code></pre>
    <h4>{`Add Remote`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git push -u origin master
  git push -u [the name] [the branch]
  # After using '-u', you can use use \`git push\` next time:
  git push
`}</code></pre>
    <h4>{`Revert a Commit`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git revert [the commit hash you want to revert]
`}</code></pre>
    <h4>{`How Git Pull Works`}</h4>
    <p>{`When you run `}<inlineCode parentName="p">{`git pull`}</inlineCode>{`, you are actually performing a series of commands:`}</p>
    <ul>
      <li parentName="ul">{`Updates the local origin/master branch by fetching updates from the origin with: `}<inlineCode parentName="li">{`git fetch`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Then the newly updated local origin/master is merged into the local master with: `}<inlineCode parentName="li">{`git merge origin/master`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Therefore, just running a `}<inlineCode parentName="p">{`git fetch`}</inlineCode>{` will pull down all of the updated code from the origin, but it will not merge any of this with the local master.`}</p>
    <h4>{`Rebase (run from feature branch)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git rebase master
`}</code></pre>
    <h4>{`Interactive Rebase (the last 4 commits)`}</h4>
    <p>{`Using 'squash' will combine this commit in with the previous commit.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  git rebase -i HEAD~4
`}</code></pre>
    <h3><a id="github"></a>{`GitHub`}</h3>
    <h4>{`Quick Search Menu:`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CMD/CTRL + K`}</inlineCode></li>
    </ul>
    <h4>{`Close an Issue With a Commit`}</h4>
    <p>{`You can add a number to your commit message to close that particular commit. I have included an example commit below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Addressing the problems with the latency. Closes #4.
`}</code></pre>
    <h3><a id="javascript"></a>{`JavaScript`}</h3>
    <h4>{`Capitalize Function`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var cap = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
`}</code></pre>
    <h4>{`Destructuring`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Object Destructuring:
let foods = {
  vegetable: "spinach",
  fruit: "apple",
  nut: "almond",
};
const { vegetable, fruit } = foods;

// Array Destructuring:
let dirty_dozen = [
  "Strawberries",
  "Spinach",
  "Nectarines",
  "Apples",
  "Peaches",
  "Pears",
  "Cherries",
  "Grapes",
  "Celery",
  "Tomatoes",
  "Sweet bell peppers",
  "Potatoes",
];

const [one_item] = dirty_dozen;
`}</code></pre>
    <h4>{`Only display content when JavaScript is enabled`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`document.getElementsByTagName("body")[0].className += " js";
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* Only set when JavaScript is enabled in browser: */
body.js .only-js {
  border: 1px solid #a0a0a0;
  display: block;
  ...;
}
`}</code></pre>
    <h4>{`ES2015 Function Syntax`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const output_log = () => {
  alert("This is an example.");
};
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const add_nums = (x, y) => {
  return x + y;
};
`}</code></pre>
    <h4>{`Detect Keyboard Input`}</h4>
    <p data-height="265" data-theme-id="dark" data-slug-hash="vZjKVr" data-default-tab="css,result" data-user="joshayoung" data-embed-version="2" data-pen-title="Detect Keyboard Input" className="codepen">See the Pen <a href="https://codepen.io/joshayoung/pen/vZjKVr/">Detect Keyboard Input</a> by Josh (<a href="https://codepen.io/joshayoung">@joshayoung</a>) on <a href="https://codepen.io">CodePen</a>.</p>
    <h4>{`Basic Class Structure`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  var Shape = function(sides) {
    this.sides = sides;
  }

  Shape.prototype.area = function() {
    calculate ...
  }

  var square = new Shape(4);
`}</code></pre>
    <h4>{`Immediately Invoked Function Expression (IIFE)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  (function() {
  ...executed immediately
  })();
`}</code></pre>
    <h4>{`String Object vs Literal`}</h4>
    <p>{`A string literal is immutable.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// String Object:
typeof new String(); // 'object'
var str = new String("Hello");
str.x = "World";
console.log(str.x); // Outputs 'World'

// String Literal:
typeof ""; // 'string'
`}</code></pre>
    <h3><a name='laravel'></a>{`Laravel`}</h3>
    <h4>{`Basic Migration`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  php artisan make:migration the_users_table --create=users
`}</code></pre>
    <h4>{`Refresh Auto Load Files`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  composer dump-autoload
`}</code></pre>
    <h4>{`Run the Migrations`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  php artisan migrate

`}</code></pre>
    <h4>{`Open Laravel Shell`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  php artisan tinker
`}</code></pre>
    <h4>{`Create the Model along with the Migration`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  php artisan make:model User -m
`}</code></pre>
    <h4>{`Create Controller`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  php artisan make:controller UserController
`}</code></pre>
    <h4>{`Create Model, Controller, and Migration`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  php artisan make:model User -mc
`}</code></pre>
    <h4>{`Create Resoureful Controller`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  php artisan make:controller UsersController -r
`}</code></pre>
    <h3><a name='freebsd'></a>{`FreeBSD`}</h3>
    <h4>{`Troubleshooting ZPool Drive Errors`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  zpool status
  glabel status
  camcontrol devlist
  geom disk list

  # Grep for info on the drive in question:
  dmesg | grep ^da1 | less

  # Find the serial on the drive in question:
  camcontrol identify da1 | grep serial
`}</code></pre>
    <h4>{`Edit Hostname`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Edit:
  vi /etc/rc.conf

  # Rename references to old hostname:
  vi /etc/hosts

  # Set the hostname
  hostname your-new-hostname

  reboot
`}</code></pre>
    <h4>{`Shutdown Server`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  poweroff
  # or
  shutdown -p now
`}</code></pre>
    <h4>{`Show Version`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  freebsd-version
`}</code></pre>
    <h4>{`Update OS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  freebsd-update fetch
  freebsd-update install
`}</code></pre>
    <h4>{`Ports`}</h4>
    <p>{`Ports are groups of 'make' files that allow software to be installed from source. They can be managed by 'portsnap'.`}</p>
    <h4>{`Packages`}</h4>
    <p>{`These packages are pre-compiled. They are similar to .deb or .rpm packages on Debian and RHEL respectively.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Package Info:
  pkg info

  # Install package:
  pkg install package_name

  # Update:
  pkg update

  # Upgrade:
  pkg upgrade

  # Uninstall package:
  pkg remove package_name
`}</code></pre>
    <h3><a name='slackware'></a>{`Slackware`}</h3>
    <h4>{`Slackware Linux Commands`}</h4>
    <p>{`Use slackpkg to keep system updated.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Download slackpkg .tar.gz file with wget
  wget https://slackpkg.org/stable/slackpkg-2.60-noarch-1.tgz

  # Install slackpkg with 'installpkg'
  installpkg slackpkg-2.60-noarch-1.tgz

  # Uncomment and select a mirror:
  vi /etc/slackpkg/mirrors

  # Initialize slackpkg:
  slackpkg update

  # Install package
  slackpkg install package_name

  # Update to the latest slackpkg packages:
  slackpkg update
  slackpkg upgrade-all
`}</code></pre>
    <h3><a id="spring"></a>{`Spring`}</h3>
    <ul>
      <li parentName="ul">{`After modifying the Spring `}<inlineCode parentName="li">{`pom.xml`}</inlineCode>{` file, you may need to right-click and tell maven to reload the project for it to properly install all of the packages.`}</li>
    </ul>
    <h3><a name='ubuntu-debian'></a>{`Ubuntu / Debian`}</h3>
    <p>{`Add or edit the lines below in this file `}<inlineCode parentName="p">{`/etc/network/interfaces`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ...
  auto eth0
  iface eth0 inet static
  address 192.168.3.100
  netmask 255.255.255.0
  gateway 192.168.3.1
  broadcast 192.168.3.255
  dns-nameservers 192.168.3.1
  ...
`}</code></pre>
    <h4>{`Show Ubuntu Version`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  lsb_release -a
  # or:
  cat /etc/*release
  # or:
  cat /etc/os-release
`}</code></pre>
    <h3><a name='centos-rhel'></a>{`CentOS / RHEL`}</h3>
    <h4>{`Change Hostname`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  hostnamectl set-hostname my-new-hostname
`}</code></pre>
    <h4>{`Show Version`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  cat /etc/*release
`}</code></pre>
    <h4>{`Add Static IP`}</h4>
    <p>{`Add modify the entries below within: `}<inlineCode parentName="p">{`/etc/sysconfig/network-scripts/ifcfg-eth0`}</inlineCode>{` (change IP addresses according to your network). The name for your network connection could differ from `}<inlineCode parentName="p">{`ifcfg-eth0`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ...
  DEVICE="eth0"
  BOOTPROTO="static"
  IPADDR="192.168.3.100"
  NETMASK="255.255.255.0"
  GATEWAY="192.168.3.1"
  DNS1="192.168.3.1"
  DNS2="192.168.3.2"
  ONBOOT="yes"
  ...
`}</code></pre>
    <h3><a name='arch'></a>{`ARCH`}</h3>
    <h4>{`Pacman Commands`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Sync and Update:
  pacman -Syu

  # Install a package:
  pacman -S package_name

  # Remove a package:
  pacman -R package_name
`}</code></pre>
    <h4>{`Show Version`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  lsb_release -a
`}</code></pre>
    <h3><a name='linux'></a>{`Linux`}</h3>
    <h4>{`Init Systems`}</h4>
    <h5>{`SystemD`}</h5>
    <p>{`Systemd is managed my 'systemctl'. To start sshd, we would run: `}<inlineCode parentName="p">{`systemctl start sshd`}</inlineCode>{`.`}</p>
    <h5>{`Upstart`}</h5>
    <p>{`Upstart is managed by 'initctl'. To start sshd we would run: `}<inlineCode parentName="p">{`initctl start sshd`}</inlineCode>{`. Upstart was invented as a replace for System V by Ubuntu.`}</p>
    <h5>{`System V (SysV)`}</h5>
    <p>{`To start sshd, we would run: '/etc/init.d/ssh start'. This was primarily used by older versions of Linux.`}</p>
    <h5>{`BSD Init`}</h5>
    <p>{`The BSD based init system is configured under /etc/rc.conf. To start sshd, we would run: '/etc/rc.d/sshd start'. BSD and Slackware use the BSD init system`}</p>
    <h4>{`Linux partition/format with Parted`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  parted /dev/sdb # Open device with gparted:
  print # Print device info:
  print free # Show free space
  help mklabel # Print available label types:
  mklabel msdos # 'msdos' good option, select 'gpt' for uefi support
  help mkpart # Partitioning help:
  mkpart # Start partitioning:

 # You will usually select 'primary' for the partition type.

 # Select filesystem type, examples:
 # ext2, ext3, ext4, ntfs, exfat, fat32

 # Start/End need adjustment if not partitioning whole drive:
 # Start partition from '2048s'
 # End on '-1s'

  quit # Exit:

  # Format your partition with 'mkfs', examples:
  mkfs.ext2 /dev/sdb1
  mkfs.ext3 /dev/sdb1
  mkfs.ext4 /dev/sdb1
  mkfs.vfat /dev/sdb1
  mkfs.ntfs /dev/sdb1

  # Use '-L' to add a label:
  mkfs.ext4 -L mydrive /dev/sdb1

  eject /dev/sdb1 # Eject partition:
`}</code></pre>
    <h4>{`Show Directory Size`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  du -sh directory_path
`}</code></pre>
    <h4>{`32 or 64 Bit OS:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  uname -m
  # or
  arch
`}</code></pre>
    <h4>{`Print Services Running on Port:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  lsof -i tcp:8000
`}</code></pre>
    <h4>{`SystemD`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # 'enable' will create a symbolic link so that the service starts on reboot
  # 'disable' will remove the symbolic link
  # 'is-active' will tell you if the service is running
  # 'is-enable' will tell you if it starts on boot
  systemctl [status, enable, disable, is-active, is-enabled start, restart, reload, stop] application.service
`}</code></pre>
    <h4>{`Scan a Folder for Viruses (ubuntu)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  sudo apt-get update
  sudo apt-get install clamav clamav-daemon

  # If you get an error here, it might be because freshclam is already running in the background:
  # This command will update the virus signatures:
  sudo freshclam

  # Scan a directory:
  clamscan -r —bell -i /path/to/directory
`}</code></pre>
    <h4>{`Change Username`}</h4>
    <p>{`If you want to switch user 'joe' to 'sally', below are the steps:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  usermod -l sally joe

  # Verify that the new user exists and the old one does not:
  id sally
  id joe

  # Swap out the new user's group:
  groupmod -n sally joe

  # Swap the home directory:
  usermod -d /home/sally -m sally

  # If the above command does not work, delete the old dir and re-create it:
  rm -rvf /home/joe
  mkhomedir_helper sally

  # If you search these two files, you should not see the 'joe' user/group:
  cat /etc/group | grep joe
  cat /etc/passwd | grep joe
`}</code></pre>
    <h4>{`Rsync`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rsync -avz --delete /original/folder/location /new/folder/location
`}</code></pre>
    <h4>{`Reload Bash Config`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  source .bashrc
`}</code></pre>
    <h4>{`Check Drive for Errors`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Health Summary:
  smartctl -H /dev/sdb
  # Time required to run HDD Test:
  smartctl -c /dev/sdb

  # Run Short Test:
  smartctl -t short /dev/sdb

  # Run Long Test:
  smartctl -t long /dev/sdb

  # Print Test Results Summary:
  smartctl -l selftest /dev/sdb

  # Print Details of Latest Test:
  smartctl -a /dev/sdb
`}</code></pre>
    <h4>{`Check Drive for Bad Blocks`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Test for bad block:
  badblocks -vs /dev/sdb > badblocks_results.txt

  # Command with different options:
  badblocks -sw -t random /dev/sdb > badblocks_results.txt

  # If you get an error like: "Value too large for defined data type invalid end block ... must be 32-bit value"
  badblocks -sw -t random -b 4096 /dev/sdb > badblocks_results.txt
`}</code></pre>
    <h4>{`Correct Bad Blocks`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # ext(2/3/4) filesystem:
  e2fsck -l badblocks_results.txt /dev/sdb

  # other filesystems:
  fsck -l badblocks_results.txt /dev/sdb
`}</code></pre>
    <h4>{`Show Security Settings with `}<inlineCode parentName="h4">{`ls`}</inlineCode></h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ls -Z
`}</code></pre>
    <h4>{`Crontab`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  *  *  *  *  *         command
  -  -  -  -  -
  |  |  |  |  |
  |  |  |  |  +---------------------- day of week (0 - 6) (0 - Sunday)
  |  |  |  +--------------------- month (1 - 12)
  |  |  +------------------- day of month (1 - 31)
  |  +------------- hour (0 - 23)
  +------------ minute (0 - 59)
`}</code></pre>
    <h4>{`Write ISO/IMG to Disk or USB`}</h4>
    <p>{`The command below could also be executed with 'bs=4M'.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  sudo fdisk -l
  sudo umount /dev/sdb
  sudo dd if=/path/to/iso/or/img of=/dev/sdb bs=1M status=progress && sync
  sudo eject /dev/sdb
`}</code></pre>
    <h4>{`Find Linux Version`}</h4>
    <h5>{`Kernel:`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  uname -mrs
`}</code></pre>
    <h5>{`Distribution:`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  cat /etc/*-release
`}</code></pre>
    <h4>{`Prevent Command from Being Recorded in Terminal History`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  <space> command
`}</code></pre>
    <h4>{`Logged In User History (last 10)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  last -10
`}</code></pre>
    <h4>{`Wipe HDD with 'dd' Command`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # With Zeros:
  dd if=/dev/zero of=/dev/sdb bs=1M status=progress && sync

  # With Random Data:
  dd if=/dev/urandom of=/dev/sdb bs=1M status=progress && sync
`}</code></pre>
    <h4>{`Terminal Calculator`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  bc -l
`}</code></pre>
    <h4>{`System Uptime`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  uptime
`}</code></pre>
    <h4>{`Clear Terminal`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  CTRL + l
`}</code></pre>
    <h4>{`Extract a .tar File`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  tar -xvf file.tar
`}</code></pre>
    <h4>{`Extract a .tar.gz File`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  tar -xzvf file.tar.gz
`}</code></pre>
    <h4>{`Extract a .tar.bz2 File`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  tar -xjvf file.tar.bz2
`}</code></pre>
    <h4>{`Finding a File`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  find / -name [file name you are searching] 2> /dev/null
`}</code></pre>
    <h4>{`Bring Job Into the Foreground`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  fg
`}</code></pre>
    <h4>{`Pause Job`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  Ctrl + Z
`}</code></pre>
    <h4>{`Resume Paused Job in Background`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  bg
`}</code></pre>
    <h4>{`Continue Execution after Shell Exit`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  nohup ./script.sh &
`}</code></pre>
    <h4>{`List Background Jobs`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  jobs
`}</code></pre>
    <h4>{`Start Program in Background`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ./script.sh &
`}</code></pre>
    <h4>{`Direct All Output to Log File`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ./script.sh 1> ~/tmp/log.txt 2> ~/tmp/log.txt &
`}</code></pre>
    <h4>{`Direct All Output to Null`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ./script.sh 1> /dev/null 2> /dev/null &
`}</code></pre>
    <h4>{`Run 256 Checksum`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  sha256sum /the/path/to/file.iso
`}</code></pre>
    <h3><a name='mac'></a>{`Mac`}</h3>
    <h4>{`Run 256 Checksum`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  shasum -a 256 /the/path/to/file.iso
`}</code></pre>
    <h4>{`Change Time Machine Backup Frequency`}</h4>
    <p>{`The interval is set in seconds.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  defaults write /System/Library/Launch Daemons/com.apple.backupd-auto StartInterval -int 1800
`}</code></pre>
    <h4>{`Find IP`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ifconfig |  grep -oE "\\w* \\b([0-9]{1,3}\\.){3}[0-9]{1,3}\\b"
`}</code></pre>
    <h4>{`Flush DNS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  killall -HUP mDNSResponder
`}</code></pre>
    <h4>{`Write ISO/IMG to Disk or USB`}</h4>
    <p>{`The command below could also be executed with 'bs=4m'.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  diskutil list
  umount /dev/disk2
  dd if=/path/to/iso/or/img/file of=/dev/disk2 bs=1m && sync
  diskutil eject /dev/disk2
`}</code></pre>
    <h3><a name='node'></a>{`Node`}</h3>
    <h4>{`NPM Packages Installed Globally:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  npm list -g --depth 0
`}</code></pre>
    <h3><a name='react'></a>{`React`}</h3>
    <h4>{`Components`}</h4>
    <ul>
      <li parentName="ul">{`Components should not be aware of state.`}</li>
    </ul>
    <h4>{`Containers`}</h4>
    <ul>
      <li parentName="ul">{`Containers are aware of state.`}</li>
      <li parentName="ul">{`Do not do styling in Containers.`}</li>
      <li parentName="ul">{`Integration test containers.`}</li>
    </ul>
    <h4>{`toContainHTML (jest)`}</h4>
    <ul>
      <li parentName="ul">{`Do not use this for testing. Use toContainElement instead of toContainHTML.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/testing-library/jest-dom#tocontainhtml"
        }}>{`toContainHTML Resource`}</a></li>
    </ul>
    <h4>{`Debugging data:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<pre>{JSON.stringify(resource)}</pre>
`}</code></pre>
    <h4>{`Commenting out JSX:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  /* <ReactComponent></ReactComponent> */
}
`}</code></pre>
    <h3><a name='mysql'></a>{`MySQL`}</h3>
    <h4>{`Connect to Database`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  mysql -u [username] -p [password] -h [hostname]
`}</code></pre>
    <h3><a name='netstat'></a>{`Netstat`}</h3>
    <h4>{`Find Listening Port (Bash)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  netstat -nao | find "80"
`}</code></pre>
    <h4>{`Find Listening Port (Windows)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  netstat -nao | findstr "0.0.80"
`}</code></pre>
    <h3><a name='mysql'></a>{`MySQL`}</h3>
    <h4>{`Connect to Database`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  mysql -u [username] -p [password] -h [hostname]
`}</code></pre>
    <h3><a name='psql'></a>{`PostgreSQL`}</h3>
    <Postgresql mdxType="Postgresql" />
    <h3><a name='kanban'></a>{`Kanban`}</h3>
    <Kanban mdxType="Kanban" />
    <h3><a name='python'></a>{`Python`}</h3>
    <h4>{`Start Simple Server`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  python -m SimpleHTTPServer
`}</code></pre>
    <h4>{`Start Simple Server with Python 3`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  python -m http.server
`}</code></pre>
    <h3><a name='rails'></a>{`Rails`}</h3>
    <h4>{`Set to Production Mode Locally:`}</h4>
    <p>{`If you are using 'dotenv-rails' to add ENV data, you may need to move that outside of your development/test section in your Gemfile.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  bin/rails assets:precompile

  bin/rails s -b 0.0.0.0 -e production
`}</code></pre>
    <h4>{`Connect to Already Running Rails Instance:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails server -P /app/tmp/pids/server.pid
`}</code></pre>
    <h4>{`Update Gems:`}</h4>
    <p>{`Updating gems that are in your lock file, but not in your Gemfile can be updated with `}<inlineCode parentName="p">{`bundle update [gem name]`}</inlineCode>{`. The gem can be upgraded to the next patch version with `}<inlineCode parentName="p">{`bundle update [gem name] --patch`}</inlineCode>{`.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`bundle update --help`}</inlineCode>{` for more info.`}</p>
    <h4>{`Start server on a different port:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails s -e development -p 2000
`}</code></pre>
    <h4>{`Start server on a different port with another pid file:`}</h4>
    <p>{`Sometimes when you are running a server on a different port, you will get an error having to do with the pid file (I forgot exactly what it says). Running a command such as the below will allow you to write to a different pid file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails s -e development -p 2000 -P tmp/pids/srv2.pid
`}</code></pre>
    <h4>{`Start rails listening on your local IP address`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails s -b 0.0.0.0
`}</code></pre>
    <h4>{`Inheriting Directly from ActionController::Base`}</h4>
    <p>{`Inheriting directly from: "ActionController::Base" instead of: "ApplicationController" will circumvent any code in your application_controller.rb file. Essentially it is like "skip running anything in the application_controller.rb file". "ApplicationController" inherits from "ActionController::Base".`}</p>
    <h4>{`Output image path from console`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ActionController::Base. \\
  helpers.asset_path('image.jpg')

  # Or:
  include ActionView::Helpers::AssetUrlHelper
  asset_path('image.jpg')
`}</code></pre>
    <h4>{`Open Rails Database Console`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails dbconsole
`}</code></pre>
    <h4>{`Clear Test Log File`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails log:clear LOGS=test
`}</code></pre>
    <h4>{`Rails Controller Methods Are Typically Ordered`}</h4>
    <ol>
      <li parentName="ol">{`index`}</li>
      <li parentName="ol">{`show`}</li>
      <li parentName="ol">{`new`}</li>
      <li parentName="ol">{`edit`}</li>
      <li parentName="ol">{`create`}</li>
      <li parentName="ol">{`update`}</li>
      <li parentName="ol">{`destroy`}</li>
    </ol>
    <h4>{`Rollback in Steps`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails db:rollback STEP=1
`}</code></pre>
    <h4>{`Show Migration Status`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails db:migrate:status
`}</code></pre>
    <h4>{`Run Migrations on Test Database`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  bin/rails db:migrate RAILS_ENV=test
`}</code></pre>
    <h4>{`Rails CRUD forms with no form plugin`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/joshayoung/rails-basic-forms"
      }}>{`github.com/joshayoung/rails-basic-forms`}</a></p>
    <h4>{`Start Local Server`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails server (rails s)
`}</code></pre>
    <h4>{`Start Local Server on any IP`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails s -b 0.0.0.0
`}</code></pre>
    <h4>{`Start Local Server on different port`}</h4>
    <p>{`The default port is 3000 if unspecified.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails s -b 0.0.0.0 -P tmp/pids/srv1.pid
  rails s -b 0.0.0.0 -p 3001 -P tmp/pids/srv2.pid
  rails s -b 0.0.0.0 -p 3002 -P tmp/pids/srv3.pid
`}</code></pre>
    <h4>{`Active Record Errors`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Model validations:
  class Student
    validates :name, :grade, :act_score, presence: true
    ...
  end

new_student = Student.create(name: 'James', grade: '2nd.')

  # Show errors:
  new_student.errors

  # Show errors as sentences:
  new_student.errors.full_messages
`}</code></pre>
    <h4>{`Route Syntax`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  verb "the_url" => "controller#action"
  get "tickets" => "tickets#index"
  get "tickets/:id" => "tickets#show"
`}</code></pre>
    <h4>{`Open SQLite from Rails`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails dbconsole
`}</code></pre>
    <h4>{`Show Rail Project Info`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails about
`}</code></pre>
    <h4>{`Open Rails Console`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails console
  or:
  rails c

  # reload the console to pull in any code changes made:
  reload!
`}</code></pre>
    <h4>{`Rails Tasks`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails -T (commands than can be run)
  rails -T db (database related commands that can be run)
`}</code></pre>
    <h4>{`Create Migration`}</h4>
    <p>{`Older versions of rails used the 'rake' command instead of 'rails' below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  rails g migration [migration name] field:type field:type...

  rails db:migrate

  rails db:status

  rails db:rollback (rolls back the previous migration)

  rails db:migrate VERSION=XXX (rolls back to this migration)
`}</code></pre>
    <h4>{`Add More Columns`}</h4>
    <p>{`Using this convention, rails will know that you want to add the fields listed to the table defined by 'YYY' or 'yyy' below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails g migration AddXXXToYYY ...
  rails g migration AddFieldsToTownships title:string moved_to:date

  rails g migration add_xxx_to_yyy
  rails g migration add_fields_to_townships
`}</code></pre>
    <h4>{`Show Rails Generators`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails g
`}</code></pre>
    <h4>{`Create Scaffolding`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails generate scaffold [name] attribute:type
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails generate scaffold Product title:string price:decimal
`}</code></pre>
    <h4>{`Create Model (includes migration)`}</h4>
    <p>{`Model names should be singular.`}</p>
    <p>{`String is the default type, so that can be left off if the type is a string.`}</p>
    <p><a parentName="p" {...{
        "href": "http://api.rubyonrails.org/v5.2.0/classes/ActiveRecord/ConnectionAdapters/SchemaStatements.html#method-i-add_column"
      }}>{`Column Types`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails generate model [model name] [field[:type][:index] field[:type][:index]] [options]

  # or:

  rails g model [model name] [field[:type][:index] field[:type][:index]] [options]

  # i.e.
  rails g model ticket title:string priority:string the_date:datetime

  # Run Migration:
  rails db:migrate

  # Migration Status:
  rails db:migrate:status
`}</code></pre>
    <p>{`Example: A model named 'Student' would point to a table named 'students' by default.`}</p>
    <h4>{`Create Controller and View`}</h4>
    <p>{`Controller name is plural.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails generate controller [controller name]
  or:
  rails g controller [controller name]
`}</code></pre>
    <h4>{`Remove Controller and other files created above.`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails destroy controller [controller name]
`}</code></pre>
    <h4>{`Basic Controller Methods`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  class TicketsController < ApplicationController
  def index; end
  def create; end
  def show; end
  def update; end
  def destroy; end
  end
`}</code></pre>
    <h4>{`Views`}</h4>
    <p>{`When you do not point the controller action to a specific view it will try to render the view with the same name as the action.`}</p>
    <p><inlineCode parentName="p">{`def index; end`}</inlineCode>{` would try to render the `}<inlineCode parentName="p">{`index.html.erb`}</inlineCode>{` view.`}</p>
    <h4>{`Create resourceful routes`}</h4>
    <p>{`This will create:`}</p>
    <ul>
      <li parentName="ul">{`Database migration for the 'students' table.`}</li>
      <li parentName="ul">{`The Student model with the 'belongs_to' line pointing to 'course'.`}</li>
      <li parentName="ul">{`A Student controller.`}</li>
      <li parentName="ul">{`All of the resourceful routes for student.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails g resource [resource name] field:type field:type...

  rails g resource student first_name:string last_name:string course:references
`}</code></pre>
    <h4>{`Show Routes`}</h4>
    <p>{`Show routes from the browser app by going to this path: http://`}{`[url of app][:port]`}{`/rails/info/routes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails routes
  # or:
  rake routes
`}</code></pre>
    <h4>{`Resourceful Routes in route.rb`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  Rails.application.routes.draw
    root "students#index"
    get "students" => "students#index", as: "students"
    post "students" => "students#create"
    get "students/new" => "students#new", as: "new_student"
    get "students/:id/edit" => "students#edit", as: "edit_student"
    get "students/:id" => "students#show", as: "student"
    patch "students/:id" => "students#update"
    put "students/:id" => "students#update"
    delete "students/:id" => "students#destroy"
  end

  # This is actually the same thing as the above:

  Rails.application.routes.draw
    root "students#index"
    resources :students
  end
`}</code></pre>
    <h4>{`One-to-Many Nested Rotues in route.rb`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  get '/lists/:list_id/notes' => 'notes#index', as: 'list_notes'
  post '/lists/:list_id/notes' => 'notes#create'
  get 'lists/:list_id/notes/new' => 'notes#new', as: 'new_list_note'
  get '/lists/:list_id/notes/:id/edit' => 'notes#edit', as: 'edit_list_note'
  get '/lists/:list_id/notes/:id' => 'notes#show', as: 'list_note'
  patch 'lists/:list_id/notes/:id' => 'notes#update'
  patch 'lists/:list_id/notes/:id' => 'notes#update'
  delete 'lists/:list_id/notes/:id' => 'notes#destroy'
`}</code></pre>
    <h4>{`List of Resourceful routes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Verb`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`URL`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`controller#action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Task`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SQL`}{`*`}{`*`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`students`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/students`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`student#index`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show Students`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SELECT `}{`*`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`POST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/students`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`students#create`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a new Student`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CREATE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`new_student`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/students/new`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`students#new`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show new Student form`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display a HTML form`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`edit_student`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/students/:id/edit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`students#edit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show edit Student form`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SELECT where id =`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`student`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/students/:id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`students#show`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show a Student`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SELECT where id =`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PATCH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/students/:id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`students#update`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Update Student (partial)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UPDATE tbl SET (name = 'Josh')`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PUT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/students/:id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`students#update`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Update Student (complete)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UPDATE tbl SET (name = 'Josh', day = 'Wed', state = 'AL' ...)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DELETE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/students/:id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`students#destroy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove a Student`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DELETE`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`*`}{`*`}{` There are exceptions to the SQL displayed here. These example serve as the most common implementations in my experience.
Each of the 'name's listed in the table above is appended with either '`}{`_`}{`url' or '`}{`_`}{`path' in rails.
In the rails app, use '`}{`_`}{`path' for your views and '`}{`_`}{`url' for controller redirects.`}</p>
    <h5>{`_`}{`url (full path to page)`}</h5>
    <ul>
      <li parentName="ul">{`i.e. students_url: `}<a parentName="li" {...{
          "href": "http://www.joshyoung.me/students"
        }}>{`http://www.joshyoung.me/students`}</a></li>
      <li parentName="ul">{`i.e. student_url(4): ttp://`}<a parentName="li" {...{
          "href": "http://www.joshyoung.me/students/4"
        }}>{`www.joshyoung.me/students/4`}</a></li>
    </ul>
    <h5>{`_`}{`path (relative path to page)`}</h5>
    <ul>
      <li parentName="ul">{`i.e. students_path: /students`}</li>
      <li parentName="ul">{`i.e. student_path(4): /students/4`}</li>
    </ul>
    <h4>{`Naming`}</h4>
    <p>{`By convention the name of the model is singular and the name of the table is plural.`}</p>
    <h4>{`Create New Table Entry`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  township = Township.new
  township.city = 'London'
  township.country = 'England'
  township.save

  # Or:

  township = Township.new(city: 'London', country: 'England')
  township.save

  # Or:

  Township.create(city: 'London', country: 'England')
`}</code></pre>
    <h4>{`Update Table Value`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  township = Township.find(2)
  township.city = 'London'
  township.country = 'England'
  township.save

  # or:

  township.update(city: 'London', country: 'England')
`}</code></pre>
    <h4>{`Delete Table Value`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  township = Township.find_by(city: 'Jacksonville')
  township.destroy
`}</code></pre>
    <h4>{`One-to-Many Relationships`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # One (parent):
  class Student < ApplicationRecord
    has_many :movies
  end

  # Many (has a foreign key to reference parent):
  class Movie < ApplicationRecord
    belongs_to :student
  end
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Setup the cascade to delete movies, then the student it removed:
  class Student < ApplicationRecord
    has_many :devices, dependent: :destroy
  end
`}</code></pre>
    <p>{`Generate the relationship:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rails g resource Device name:string student:references
`}</code></pre>
    <h5>{`One (parent):`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`students`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`age`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Frank`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sally`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Many (child):`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`devices`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`student_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iPhone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iPad`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Laptop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In the above example, 'student_id' is a foreign key pointing to the 'id' field in the 'students' table.
The foreign key will always be the parent's table name in singular form (student) with an '`}{`_`}{`id' appended to the end. So in this case it is 'student_id' since the parent table is 'students'.`}</p>
    <p>{`In the ruby console, `}<strong parentName="p">{`student.devices`}</strong>{` would return all of the devices that student possesses. On the other hand, `}<strong parentName="p">{`device.student`}</strong>{` would return the student who is the owner of the device selected.`}</p>
    <h4>{`Many-to-Many Relationships`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  class Student < ApplicationRecord
    has_many :devices
  end

  class Device < ApplicationRecord
    belongs_to :student
    belongs_to :colors
  end

  class Color < ApplicationRecord
    has_many :devices
  end
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`students`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`age`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Frank`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sally`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`devices`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`student_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color_id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iPhone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iPad`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Laptop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`colors`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Green`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blue`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Silver`}</td>
        </tr>
      </tbody>
    </table>
    <h3><a name='ruby'></a>{`Ruby`}</h3>
    <h4>{`Messages`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`# \`name\` is the message sent to \`animal\`
  animal.name
`}</code></pre>
    <h4>{`Write to file from command line:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  f = File.new('log.txt', 'w')
  f << log_this_variables_value
  f.close
`}</code></pre>
    <h4>{`Output just a class' own methods`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  klass = Klass.new

  puts klass.methods - methods
`}</code></pre>
    <h4>{`Loads IRB With Active Support`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  !rails c
`}</code></pre>
    <h4>{`Struct`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # With Struct you can only define
  # the attributes at object creation
  Student = Struct.new(:name, :grade)
  george = Student.new('george', 95)

  # Outputs: 'george'
  puts george.name

  # Outputs: 95
  puts george.grade
`}</code></pre>
    <h4>{`OpenStruct`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  require 'ostruct'
  george = OpenStruct.new(name: 'george', grade: 95)

  # Outputs: 'george'
  puts george.name

  # Outputs: 95
  puts george.grade
`}</code></pre>
    <h4>{`Open documentation for project's gems`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  gem serve
`}</code></pre>
    <h4>{`Install to global gemset (when using rvm)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rvm @global do gem install [gem name]
`}</code></pre>
    <h4>{`Empty Method Returns Nil`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  def find_grade; end
  grade = find_grade

  # Outputs nil:
  puts grade
`}</code></pre>
    <h4>{`Naming`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Class is a Noun:
  class Cards
    # Module is an Adjective:
    include Shuffleable
  end
`}</code></pre>
    <h4>{`Ranges`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Inclusive:
  5..10
  # Exclusive:
  5...10
`}</code></pre>
    <h4>{`Find Methods`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  cat.private_methods
  cat.public_methods
  cat.protected_methods
  cat.singleton_methods

  Cat.private_instance_methods
  Cat.protected_instance_methods
  Cat.public_instance_methods
`}</code></pre>
    <h4>{`Syntactic Sugar`}</h4>
    <p>{`At the end of the day, the operators we know like `}<inlineCode parentName="p">{`+`}</inlineCode>{`, `}<inlineCode parentName="p">{`-`}</inlineCode>{`, etc are actually methods in ruby. So these two will do the same thing:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  y = 5 + 6;
  y = 5.+(6)
`}</code></pre>
    <h4>{`Methods and Code Blocks`}</h4>
    <p>{`By default all methods will accept a code block. However, they will not yield to the block unless the method includes a `}<inlineCode parentName="p">{`yield`}</inlineCode>{` keyword.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # These are both valid ways to call a method:
  my_method
  my_method { puts "Test" }
  my_method() { puts "Test" }
`}</code></pre>
    <h4>{`Accept Multiple Arguments`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  def lots_of(*a)
  end

  # Outpus: [1, 2, 3, 4]
  puts lots_of(1, 2, 3, 4)
`}</code></pre>
    <h4>{`Multiline Comment`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`=begin
  def a_function
  puts 'test'
  end
=end
`}</code></pre>
    <h4>{`Ruby Symbol vs Strings`}</h4>
    <p>{`Symbols have the same object ID whereas strings do not.
Symbols are immutable and strings are mutable.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  # Open irb:
  >> :pathway.object_id
  => 9854917
  >>
  >> :pathway.object_id
  => 9854917
  >>
  >> "pathway".object_id
  => 97508076985508
  >>
  >> "pathway".object_id
  => 98508075985400
  >>
  >> :pathway == "pathway"
  => false
`}</code></pre>
    <h4>{`Ruby Class Properties`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  class RubyStudent
    # readable only:
    attr_reader :name

    # writable only:
    attr_writer :name

    # readable and writable:
    attr_accessor :grade

    def initialize
      @name = 'Josh'
      @grade = 'a'
    end

    # Override the attr_accessor method in your class:
    def grade=(my_grade)
      @grade = my_grade.upcase
    end
    ...
  end

  rstudent = RubyStudent.new

  # Call the accessor method:
  puts rstudent.name
`}</code></pre>
    <h4>{`Static Methods`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  class RubyStudent
    def self.my_method
      ...
    end
  end

  # Call static method:
  RubyStudent.my_method
`}</code></pre>
    <h4>{`Multiple Static Methods`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  class RubyStudent
    class << self
      def my_method
        ...
      end

      def my_second_method
        ...
      end
    end
  end

  # Call static method:
  RubyStudent.my_method
  RubyStudent.my_second_method
`}</code></pre>
    <h4>{`Ruby Modules`}</h4>
    <p>{`Modules are a typically used to create reusable sections of code in the form of Mixins or they can be used to wrap classes thereby action as a Namespace.`}</p>
    <p>{`Modules cannot be instantiated, so any methods have to be defined with self.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  module Learnable
    ...
    def self.calculate
      ...
    end
  end

  # Call the Module method:
  Learnable::calculate
  # or
  Learnable.calculate
`}</code></pre>
    <h4>{`Ruby Class Inheritance`}</h4>
    <p>{`Use inheritance when two class have a 'is-a' relationship. For instance: a MathStudent is a type of Student and a Fox is a type of Animal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  class Student
    ...
    def print_grade
      puts 'A'
    end
  end

  class MathStudent < Student
    def print_grade
      puts 'A+'
    end
  end

  student = MathStudent.new

  # Prints 'A+':
  student.print_grade
`}</code></pre>
    <h4>{`Ruby Namespace`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  module CollegeStudent
    class Student
      def self.outp
        puts 'output'
      end
      def out
        puts 'new output'
      end
    end
  end

  CollegeStudent::Student.outp
  student = CollegeStudent::Student.new
  student.out
`}</code></pre>
    <h4>{`Ruby Mixins`}</h4>
    <p>{`Ruby Mixins are just ruby modules that are included within a class.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # If a module will be included in a class as a mixin, you do not have to define the method with self.
  # This way you can call the module method with the class instantiation.

  # File 'learnable.rb'
  module Learnable
    def calculate(num1, num2)
      num1 * num2
    end
  end

  # File: student.rb
  require_relative 'learnable'
  class Student
    include Learnable

    def add_up(n1, n2)
      calculate(n1, n2)
    end
  end

  student = Student.new
  puts student.calculate(2, 2)
`}</code></pre>
    <p>{`When you define a mixin, if you know what class it will be included within, you can use class properties in the Module definition, like below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  module Learnable
    def calculate(num1, num2)
      @first_number = 10
    end
  end
`}</code></pre>
    <p>{`However, doing so could cause problems if you ever include the module within a class that does not define '@first_number'. Therefore it is best to use the class access within the module methods, like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  module Learnable
    def calculate(num1, num2)
      #Here we have to use 'self' otherwise the module will think it is defining a local variable.
      self.first_number = 10
    end
  end
`}</code></pre>
    <p>{`Then when this is included in a class, it will work like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  require_relative 'learnable'
  class Student
    include Learnable

    attr_accessor :first_number

    def initialize
      calculate(2, 2)
    end
  end

  puts Student.new.first_number
`}</code></pre>
    <h4>{`Unless vs If`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Prints 'false unless':
  unless false
    puts 'false unless'
  end

  # Prints 'false unless':
  puts 'false unless' unless false

  # Prints 'false if':
  if !false
    puts 'false if'
  end

  # Prints 'false if':
  puts 'false if' if !false
`}</code></pre>
    <h4>{`Memoization`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # If 'x' is false or nil return the value of 'y',
  # otherwise return the value of 'x':
  x ||= y

  # The first time through it will call the 'find_the_pizza' method.
  # For subsequent calls it will return '@pizza'.
  def pizza_special
    @pizza ||= PizzaSpecial.find_the_pizza('cheese')
  end
`}</code></pre>
    <h4>{`When You Must Use 'self'.`}</h4>
    <h5>{`For assigning values with the accessor method from within a class:`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  class Animal
    attr_accessor :tail

    def change_tail(new_tail)
      # To get this to work as expected, you would have
      # to change the line below to use 'self'
      tail = new_tail

      # Change to this:
      # self.tail = new_tail

      # If you do not use 'self' as shown above, ruby
      # thinks you are assigning to a local variable.

      # This works without 'self' because ruby knows that there is no local
      # variable titled 'tail' therefore it knows to return the value from the
      # accessor method:
      def send_tail
        tail
      end
    end
  end

  the_tail = Animal.new
  the_tail.tail = 'Brown'
  the_tail.change_tail('light brown')

  # This would output 'Brown'.
  puts the_tail.tail
`}</code></pre>
    <h5>{`For defining 'class methods' (a.k.a. Static Methods):`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  class Student
    def self.new_grade(grade)
      @grade = grade
    end
  end

  Student.new_grade('A')
`}</code></pre>
    <h4>{`Convenient Methods`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  class Test
  ...
  end

  # Prints: Test
  puts Test.inspect

  # Prints: Class
  puts Test.class

  # Prints: 89023478923
  puts test.object_id
`}</code></pre>
    <h4>{`Show Ancestors`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # In a file named 'my_module.rb':
  module MyMod
    ...
  end

  # In another class:
  require_relative 'my_module'
  class Test
    include MyMod
    ...
  end

  # Prints: [Test, MyMod, Object, Kernel, BasicObject]
  puts Test.ancestors
`}</code></pre>
    <h4>{`Function Return`}</h4>
    <p>{`Functions always return a value even if they are empty.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  def empty_function; end

  return_value = empty_function

  # Prints 'nil':
  puts return_value.inspect
`}</code></pre>
    <h4>{`Ruby Convert Types`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  a_string = 'a string value'

  # Convert to Symbol:
  a_string.to_sym

  # Convert to String:
  123.to_s

  # Convert to Integer:
  "123".to_i
`}</code></pre>
    <h4>{`Look up Ruby Docs from command line`}</h4>
    <p><a parentName="p" {...{
        "href": "http://ruby-doc.org"
      }}>{`Online Documentation`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ri
  # or:
  ri -i (for interactive mode)
`}</code></pre>
    <h4>{`Interpolate code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  variable_value = 'test'
  puts "Print out #{variable_value}"
`}</code></pre>
    <h4>{`Start IRB Session`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  irb
`}</code></pre>
    <h4>{`If/Else Statement`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  if a_value == 1
  puts "Yes"
  elsif a_value == 2
  puts "No"
  else
  puts "Could not find."
  end
`}</code></pre>
    <h4>{`Switch Statement`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  the_value = return_a_string()

  case the_value
  when "One"
    puts "It is one!"
  when "Two"
    puts "It is two!"
  else
    puts "Could not find result."
  end
`}</code></pre>
    <h4>{`Objects`}</h4>
    <p>{`Ruby objects are always passed by reference`}</p>
    <h4>{`Function`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  def function_name(parameter)
    ...
  end
`}</code></pre>
    <h4>{`Add to Array`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  the_array << "val"

  # Or:
  the_array.push("val")
`}</code></pre>
    <h4>{`Object Instantiation`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  new_obj = Person.new
`}</code></pre>
    <h4>{`Iteration`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  array.each do |elem| ... end
`}</code></pre>
    <h4>{`Custom Iteration`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Example 1:
  def output
    yield
    yield
  end

  output { puts "This is output twice" }

  # Example 2:
  def output_num
    yield(100)
    yield(200)
  end

  output_num { |num| puts num }
`}</code></pre>
    <h4>{`Basic Blocks`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  2.times { puts 'Josh' }

  2.times do
    puts 'Josh'
  end

  2.times { |i| puts "#{i} - Josh" }

  2.times do |i|
    puts "#{i} - Josh"
  end
`}</code></pre>
    <h4>{`Select Block`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Returns just the numbers greater than '3':
  [1, 2, 3, 4, 5, 6, 7, 8, 9].select { |n| n > 3 }
`}</code></pre>
    <h4>{`Reject Block`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Rejects the numbers greater than '3':
  [1, 2, 3, 4, 5, 6, 7, 8, 9].reject { |n| n > 3 }
`}</code></pre>
    <h4>{`Reduce Block`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Gets the sum with the reduce method:
  [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce { |total, n| total + n }
  # or:
  [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce (:+)
`}</code></pre>
    <h4>{`Sort Lowest to Highest`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  [11, 21, 73, 14, 95, 56, 97, 48, 19].sort
`}</code></pre>
    <h4>{`Sort Highest to Lowest`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  [11, 21, 73, 14, 95, 56, 97, 48, 19].sort { |a, b| b <=> a }
`}</code></pre>
    <h4>{`Returns boolean if it finds the result`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # This should return false.
  [11, 21, 73, 14, 95, 56, 97, 48, 19].any? { |n| n > 100 }
`}</code></pre>
    <h4>{`Returns the first match`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # This should return 73.
  [11, 21, 73, 14, 95, 56, 97, 48, 19].detect { |n| n > 70 }
`}</code></pre>
    <h4>{`Map the values returned into a new array`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  [11, 21, 73, 14, 95, 19].map { |n| n * 3 }
`}</code></pre>
    <h4>{`Hash (associative array / dictionary)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  {key => value}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  {:sport => "baseball"} (the key can be anything)
`}</code></pre>
    <p>{`These are both the same:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  {:sport => "baseball", :time_limit => 60}

  # Or:
  {sport: "baseball", time_limit: 60}
`}</code></pre>
    <h4>{`Loop through a hash`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  sports = {:sport => "baseball", :time_limit => 60}
  sports.each { |key, val| puts "#{key} - #{val}" }
`}</code></pre>
    <h3><a name='sqlite'></a>{`SQLite`}</h3>
    <h4>{`Execute a Single Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  sqlite3 -line mydatabase.sqlite3 "select * from students"
`}</code></pre>
    <h4>{`Select Databse`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  \\c [database]
`}</code></pre>
    <h4>{`Connect to DB`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  sqlite3 /path/to/database_file.db
`}</code></pre>
    <h4>{`Show databases`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  .database
`}</code></pre>
    <h4>{`Show tables`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  .tables
`}</code></pre>
    <h4>{`Tables Schema`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  .schema table_name
`}</code></pre>
    <h4>{`Describe Table`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  pragma table_info(table_name)
`}</code></pre>
    <h4>{`Select all`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  select * from table_name
`}</code></pre>
    <h4>{`Exit SqLite Command Prompt:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  .quit
`}</code></pre>
    <h3><a name='security'></a>{`Security`}</h3>
    <h4>{`Ping Sweep`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  for i in \`seq 1 255\`; do ping -c 1 [IP ADDRESS].$i ; done
`}</code></pre>
    <h3><a name='software_engineering'></a>{`Software Engineering`}</h3>
    <h4>{`Gherkin Success Criteria`}</h4>
    <h5>{`Scenario: The Widget Should Play a Random Song`}</h5>
    <ul>
      <li parentName="ul"><strong parentName="li">{`GIVEN`}</strong>{`: The widget is in the closed position.`}</li>
      <li parentName="ul"><strong parentName="li">{`AND`}</strong>{`: It has never been opened.`}</li>
      <li parentName="ul"><strong parentName="li">{`WHEN`}</strong>{`: You open it.`}</li>
      <li parentName="ul"><strong parentName="li">{`THEN`}</strong>{`: It plays a random song.`}</li>
    </ul>
    <h5>{`Resources:`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://findanyanswer.com/how-do-you-write-gherkin-acceptance-criteria"
        }}>{`How do you write Gherkin acceptance criteria`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.tutorialspoint.com/behavior_driven_development/behavior_driven_development_gherkin.htm"
        }}>{`Behavior Driven Development - Gherkin`}</a></li>
    </ul>
    <h4>{`Semantic versioning`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Major Version . Minor Version . Patch
1.4.9
`}</code></pre>
    <ul>
      <li parentName="ul">{`The first number represents the major version. The second number represents the minor version. The third number represents the current patch level.`}</li>
      <li parentName="ul">{`Typically if the first number changes that would represent a breaking change.`}</li>
      <li parentName="ul">{`If the second number changes, that would represent a new feature.`}</li>
      <li parentName="ul">{`If the third number changes, that would represent a bug fix.`}</li>
    </ul>
    <h3><a name='testing'></a>{`Testing`}</h3>
    <h4>{`Rspec Fail on First Error`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  rspec --fail-fast
`}</code></pre>
    <h4>{`Testing DateTime`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://methodpoet.com/unit-testing-datetime-now/"
        }}>{`https://methodpoet.com/unit-testing-datetime-now/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dev.to/pnieweglowski/why-using-datetime-now-directly-is-a-bad-idea-1gob"
        }}>{`https://dev.to/pnieweglowski/why-using-datetime-now-directly-is-a-bad-idea-1gob`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cosmin-vladutu.medium.com/how-to-mock-datetime-in-unit-tests-80dad50b8508"
        }}>{`https://cosmin-vladutu.medium.com/how-to-mock-datetime-in-unit-tests-80dad50b8508`}</a></li>
      <li parentName="ul">{`To test a method with a DateTime in C#, you have a few options.`}
        <ul parentName="li">
          <li parentName="ul">{`Inject a class that calls DateTime.Now, which you can then mock.`}
            <ul parentName="li">
              <li parentName="ul">{`This only works if you can use an instance method. If you need to mock a static value, then you can follow the last recommendation here: `}<a parentName="li" {...{
                  "href": "https://methodpoet.com/unit-testing-datetime-now/"
                }}>{`https://methodpoet.com/unit-testing-datetime-now/`}</a>{` which works really well, but is complicated.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Or you can create a class that calls a function which returns DateTime.Now.`}</li>
          <li parentName="ul">{`Optionally, you can use Pose to mock the DateTime value.`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`Rspec with Documentation`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  rspec -f d
`}</code></pre>
    <h4>{`Test File Setup`}</h4>
    <p>{`We have a space between each part below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`  # Setup:
  user = create(:user)

  # Exercise:
  Student.addToClass(user)

  # Verify:
  expect(Student.users).to eq([user])
`}</code></pre>
    <h3><a name='ssh'></a>{`SSH`}</h3>
    <h4>{`Remote Port Forwarding`}</h4>
    <p>{`Here `}<strong parentName="p">{`9100`}</strong>{` is the remote port and `}<strong parentName="p">{`22`}</strong>{` is the remote port.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ssh -R 9100:127.0.0.1:22 username@192.168.6.7
`}</code></pre>
    <h4>{`Generate a SSH and transfer it to a server`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ssh-keygen -t ed25519 -C "comment"
  ssh-copy-id -i ~/.ssh/key_name.pub user@192.168.1.100

  Note: If you get permission errors after trying to connect with the key, make sure that you have this key setup in your \`~/.ssh/config\` file.
`}</code></pre>
    <h4>{`Sample config for `}<inlineCode parentName="h4">{`~/.ssh/config`}</inlineCode>{` file:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Host 192.168.1.100
   IdentityFile ~/.ssh/key_name
`}</code></pre>
    <h4>{`Connect with a specific key`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ssh -i ~/.ssh/key_name user@192.168.1.100
`}</code></pre>
    <h4>{`Local Port Forward`}</h4>
    <p>{`In this case, `}<strong parentName="p">{`9100`}</strong>{` would be our local port and `}<strong parentName="p">{`80`}</strong>{` would be the remote.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ssh -L 9100:www.remotesite.com:80 username@host
`}</code></pre>
    <h3><a name='svg'></a>{`SVG`}</h3>
    <h4>{`SVG viewBox`}</h4>
    <p>{`The viewBox has values of x, y, width, and height. The shapes within this box will be set in relation to the size of the viewBox. The viewBox location within the SVG tag can be offset with the first two numbers (x, y).`}</p>
    <p>{`In the CodePen below below, you can see that the first and last example are consuming 100% of the width of the viewBox. When the screen is expanded or contracted, the elements within expand or contract accordingly, but always consume 100% of the size of the viewBox because the elements inside are sized to contain 100% of the viewBox width.`}</p>
    <p>{`The middle example below internal shapes that total to less than the full width of the viewBox. The viewBox is also shifted from a starting point of 0,0 to demonstrate that the view box can be positioned anywhere within the contining SVG element.`}</p>
    <p>{`To adjust the size of the SVG, you can set the outer containing `}<inlineCode parentName="p">{`SVG`}</inlineCode>{` tag to be a certain percentage of the width of the browser window or even a fixed width. Within this the inner viewBox can be positioned within it.`}</p>
    <p data-height="265" data-theme-id="0" data-slug-hash="xzgvKX" data-default-tab="html,result" data-user="joshayoung" data-embed-version="2" data-pen-title="SVG viewBox" className="codepen">See the Pen <a href="https://codepen.io/joshayoung/pen/xzgvKX/">SVG viewBox</a> by Josh Young (<a href="https://codepen.io/joshayoung">@joshayoung</a>) on <a href="https://codepen.io">CodePen</a>.</p>
    <h4>{`SVG Links`}</h4>
    <p data-height="265" data-theme-id="0" data-slug-hash="xzgodE" data-default-tab="html,result" data-user="joshayoung" data-embed-version="2" data-pen-title="SVG Symbol" className="codepen">See the Pen <a href="https://codepen.io/joshayoung/pen/xzgodE/">SVG Symbol</a> by Josh Young (<a href="https://codepen.io/joshayoung">@joshayoung</a>) on <a href="https://codepen.io">CodePen</a>.</p>
    <h4>{`SVG Elements`}</h4>
    <p data-height="265" data-theme-id="0" data-slug-hash="wXgNvg" data-default-tab="html,result" data-user="joshayoung" data-embed-version="2" data-pen-title="SVG Stuff" className="codepen">See the Pen <a href="https://codepen.io/joshayoung/pen/wXgNvg/">SVG Stuff</a> by Josh Young (<a href="https://codepen.io/joshayoung">@joshayoung</a>) on <a href="https://codepen.io">CodePen</a>.</p>
    <h4>{`Set SVG to Scale with Browser Width`}</h4>
    <p>{`By default an SVG element will take up the full width of the browser unless it has a defined width. To circumvent this, you can give it a definined with such as 300px or a percentage. Below is an example of a way to set the SVG element to be half of the browser width.`}</p>
    <p>{`If the SVG tag is set to scale with the browser width, the SVG shapes within the internal viewBox will scale accordingly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`svg {
  height: auto;
  // This could be any percentage:
  width: 50%;
}
`}</code></pre>
    <h3><a name='rider'></a>{`Rider`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Alt + Insert`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Generation Menu.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`CMD/CTRL + n`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Add Controller`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`CMD + OPTION + L`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Format Code`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`CMD + D`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Navigation to Source`}</li>
        </ul>
      </li>
    </ul>
    <h3><a name='rubymine'></a>{`RubyMine`}</h3>
    <Rubymine mdxType="Rubymine" />
    <h3><a name='intellij'></a>{`IntelliJ`}</h3>
    <ul>
      <li parentName="ul">{`Context menu to add class components`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`CMD + N`}</strong></li>
        </ul>
      </li>
    </ul>
    <h3><a name='scp'></a>{`SCP`}</h3>
    <h4>{`Local to Remote`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  scp file.txt username@host:/to/myremote/directory
`}</code></pre>
    <h4>{`Remote to Local`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  scp username@host:file.txt /to/my/local/directory
`}</code></pre>
    <h4>{`Backup Local Folder to Remote`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  scp -r local_folder/ user@host:/path/to/folder
`}</code></pre>
    <h4>{`Backup Folder Remote to Local`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  scp -r user@host:/path/to/folder/* /to/my/local/directory/
`}</code></pre>
    <h4>{`Backup Folder Remote to Local, with Port`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  scp -r -P 8000 user@host:/path/to/folder/* /to/my/local/directory/
`}</code></pre>
    <h3><a name='security'></a>{`Security`}</h3>
    <h4>{`Nmap Ping Scan (detect hosts)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  nmap -sP 192.168.1.0/24
`}</code></pre>
    <h4>{`Bypass a Self-Signed SSL Warning in Chrome`}</h4>
    <p>{`Once you get the warning in the webpage, to continue to the site anyway, type: `}<inlineCode parentName="p">{`thisisunsafe`}</inlineCode>{`.`}</p>
    <h4>{`Bash Ping Sweep`}</h4>
    <h4>{`Usage: `}<strong parentName="h4">{`./sweep.sh 192.168.1`}</strong></h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  #!/bin/bash
  ip=$1
  for i in \`seq 0 1 255\`; do
    ping -c 3 -t 5 $ip.$i > /dev/null 2>&1 && echo $ip.$i is up;
  done
`}</code></pre>
    <h3><a name='php'></a>{`PHP`}</h3>
    <h4>{`GET/POST Assignment`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`  $var = isset($_GET['var']) ? $_GET['var'] : '';
`}</code></pre>
    <h3><a name='tmux'></a>{`TMUX`}</h3>
    <TMUX mdxType="TMUX" />
    <h3><a name='vim'></a>{`VIM`}</h3>
    <VIM mdxType="VIM" />
    <h3><a name='vs_code'></a>{`VS Code`}</h3>
    <VSCode mdxType="VSCode" />
    <h3><a name='visual_studio'></a>{`Visual Studio`}</h3>
    <VisualStudio mdxType="VisualStudio" />
    <h3><a name='windows'></a>{`Windows`}</h3>
    <h4>{`Switch to C Drive`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  cd /d C:
`}</code></pre>
    <h4>{`Flush DNS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ipconfig /flushdns
`}</code></pre>
    <h4>{`All Network Info`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  ipconfig /all
`}</code></pre>
    <h3><a name='xamarin-maui'></a>{`Xamarin / MAUI`}</h3>
    <h4>{`Push a New Content Page`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`  Navigation.PushAsync(new ContentPage());
`}</code></pre>
    <h4>{`Delete bin and obj files`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`find . -iname "bin" -o -iname "obj" | xargs rm -rf`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://stackoverflow.com/questions/755382/i-want-to-delete-all-bin-and-obj-folders-to-force-all-projects-to-rebuild-everyt"
            }}>{`https://stackoverflow.com/questions/755382/i-want-to-delete-all-bin-and-obj-folders-to-force-all-projects-to-rebuild-everyt`}</a></li>
        </ul>
      </li>
    </ul>
    <h5>{`Install .NET from the Command Line`}</h5>
    <ul>
      <li parentName="ul">{`\`sudo bash dotnet-install.sh --channel 8.0.1xx --install-dir /usr/local/share/dotnet\`\``}</li>
    </ul>
    <h4>{`Change Maui App Icon`}</h4>
    <ul>
      <li parentName="ul">{`Move the existing app icon into the 'raw' folder.`}</li>
      <li parentName="ul">{`Copy your new icon to the AppIcon folder and make ensure it is named 'appicon.`}{`[ext]`}{`'.`}
        <ul parentName="li">
          <li parentName="ul">{`'ext' could be svg or png`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Refactor-delete the copied icons from the raw folder.`}</li>
      <li parentName="ul">{`Make sure the new appicon is set to 'MauiIcon' in the build settings.`}</li>
      <li parentName="ul">{`clean`}</li>
      <li parentName="ul">{`rebuild`}</li>
    </ul>
    <h5>{`Resources`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=iigquyjXlM0"
        }}>{`https://www.youtube.com/watch?v=iigquyjXlM0`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=jXuuoBlQFD4"
        }}>{`https://www.youtube.com/watch?v=jXuuoBlQFD4`}</a></li>
    </ul>
    <h4>{`Margin:`}</h4>
    <ul>
      <li parentName="ul">{`left/right, top/bottom`}</li>
      <li parentName="ul">{`left, top, right, bottom`}</li>
    </ul>
    <h4>{`Margin with CSS:`}</h4>
    <ul>
      <li parentName="ul">{`top/bottom, left/right`}</li>
      <li parentName="ul">{`top, left/right, bottom`}</li>
      <li parentName="ul">{`top, right, bottom, left`}</li>
    </ul>
    <h3><a name='yarn'></a>{`YARN`}</h3>
    <h4>{`Update all packages in `}<inlineCode parentName="h4">{`packge.json`}</inlineCode>{` to the lates version with:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  yarn upgrade
`}</code></pre>
    <p>{`Upgrade to specific versions with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  yarn upgrade package@version
`}</code></pre>
    <p>{`Reference: `}<a parentName="p" {...{
        "href": "https://classic.yarnpkg.com/en/docs/cli/upgrade"
      }}>{`yarn upgrade`}</a></p>
    <h4>{`Package Version (exclude dependencies)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  yarn list --depth 0 | grep [package name]
`}</code></pre>
    <h4>{`Show why a package was installed:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  yarn why
`}</code></pre>
    <h3><a name='zfs'></a>{`ZFS`}</h3>
    <h4>{`ZFS Pool Status`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`  zpool status -v

  zpool history

  # List Datasets:
  zfs list

  # Display all information about all datasets:
  zfs get all

  # Mount filesystem:
  zfs mount /mount_location
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      